import React, { Component } from 'react'

import backgroundImage from '../Assets/back-launch.jpg'
import logoGram from '../Assets/logo-gram.png'
import activityLogoImage from '../Assets/logo-exness.png'

import Logo from '../Components/Logo'

import { connect } from 'react-redux'
import GameActions from '../Redux/Game'

import classNames from 'classnames'

import './Styles/GeneralStyles.css'
import './Styles/LaunchScreenStyles.css'

class LaunchScreen extends Component {

  componentDidMount() {
    this.props.exportPlayers()
  }


  render() {
    return (
      <div
        style={ { backgroundImage: 'url('+backgroundImage+')', backgroundSize: 'cover', backgroundPosition: 'center' } }
        className={classNames(['screen', 'LaunchScreenContainer']) }>

        <div>
        </div>

        <div onClick={  () => this.props.history.push({ pathname: '/user-data' }) }>
          <img src={ logoGram } />
        </div>
        <div>
          <img src={activityLogoImage} />
        </div>




      </div>
    )
  }

}


const mapDispatchToProps = dispatch => {
  return {
    exportPlayers: () => dispatch( GameActions.exportPlayers() ),
  }
}

export default connect(null, mapDispatchToProps) (LaunchScreen)
