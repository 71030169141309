import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'

import { Provider } from 'react-redux'
import store from './Redux'
import mySagas from './Sagas'

import { offline } from '@redux-offline/redux-offline'
import defaultConfig from '@redux-offline/redux-offline/lib/defaults'
import { seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = composeWithDevTools({})

const createdStore = createStore(store,
  composeEnhancers (
    applyMiddleware(sagaMiddleware),
    offline({
      ...defaultConfig,
      persistOptions: {
        transforms: [seamlessImmutableTransformCreator({})],
      },
    })
  )
)

sagaMiddleware.run(mySagas)

ReactDOM.render(
  <Provider store={ createdStore } >
    <App />
  </Provider>,
  document.getElementById('root'))
registerServiceWorker()
