import React, { Component } from 'react'

import backgroundImage from '../Assets/back-launch-logo.jpg'
import userIcon from '../Assets/photo.png'
import activityLogoImage from '../Assets/logo-exness.png'
import qrImage from '../Assets/qr-1.png'
import logoGram from '../Assets/logo-gram.png'

import { connect } from 'react-redux'

import GameActions from '../Redux/Game'

import './Styles/UserDataScreenStyles.css'
import './Styles/GeneralStyles.css'

class UserDataScreen extends Component {

  goNext(event) {

    event.preventDefault()
    this.props.history.push({ pathname: '/character' })

  }

  render() {

    return (
      <div
        className="screen"
        id="user-data-screen"
        style={ { backgroundImage: 'url('+backgroundImage+')', backgroundSize: 'cover', backgroundPosition: 'center' } }>
        <div style={{ paddingTop: 87 }}>
          <img src={activityLogoImage} />
        </div>
        <form onSubmit={ event => this.goNext(event)}>
          <div id="box-form">
            <img src={userIcon} className="user-icon" />
            
            <div className="box-container-fields">
              <div>
                <img src={qrImage} />
              </div>
              <div className='subtitle'>
                <p>1. Escanea este QR y crea tu cuenta Exness</p>
              </div>
            </div>
            <div className="background" />
          </div>
          <input id="submit" type="submit" value="empezar" />
        </form>
        <div style={{ paddingBottom: 87 }}>
          <img src={ logoGram } width={150}  height={150} />  
        </div>
    
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startGameForUser: (name, email, dni, phone, signature) =>
      dispatch( GameActions.startGameForUser(name, email, dni, phone, signature) )
  }
}

export default connect(null, mapDispatchToProps) (UserDataScreen)
