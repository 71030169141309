import { useEffect, useRef } from 'react'
import { Wedge, Group, Layer, Stage, Image, Text } from 'react-konva'
import PropTypes from 'prop-types'
import Konva from 'konva'
import useImage from 'use-image'

const Slice = ({ isEnabled, image, radius, rotation, angle, color, scale, center }) => {

  const wedgeRef = useRef()

  const iconImageWidth = 95
  const iconImageHeight = 95

  const iconImageX = isEnabled ? 65 : 65
  const iconImageY = isEnabled ? 144 : 228

  const [imageElement] = useImage(image)

  useEffect( () => {
    if (wedgeRef) {
      wedgeRef.current.startRotation = wedgeRef.current.getRotation()
    }
  }, [wedgeRef])


  return (
    <Group
      rotation={ rotation }>

      <Wedge
        ref={ wedgeRef }
        radius={ radius }
        angle={ angle }
        fill={ isEnabled ? color : 'black' } />

      <Image
        width = { iconImageWidth * scale }
        height = { iconImageHeight * scale }
        x={ center.x - (iconImageX * scale)}
        y={ center.y - (iconImageY * scale)}
        rotation={ (Math.PI + angle ) / 2}
        image={ imageElement } />

        { !isEnabled && (
          <Text
            fontSize={22 * scale}
            wrap="word"
            width={120 * scale}
            x={240 * scale}
            y={35 * scale}
            rotation={ (Math.PI + angle ) / 2}
            fill="#EB6607"
            text="Premio No disponible" />
        )}
    </Group>
  )

}

export default Slice
