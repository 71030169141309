import { call, put, all, takeLatest } from 'redux-saga/effects'

import { GameTypes } from '../Redux/Game'

import { fetchCategories } from './Categories'
import { exportPlayers } from './Players'

export default function * root () {
  yield all([
    takeLatest( GameTypes.FETCH_QUESTIONS, fetchCategories ),
    takeLatest( GameTypes.EXPORT_PLAYERS, exportPlayers )
  ])
}
