import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchCategoriesService } from '../Services/Categories'

import GameActions from '../Redux/Game'

export function * fetchCategories(action) {
  const response = yield call( fetchCategoriesService )
  console.log('Categories response: ', response)
  if (!response.ok && response.problem === 'NETWORK_ERROR')
    return yield put( GameActions.fetchQuestionsFailed('NETWORK_ERROR') )

  if (!response.ok) return yield put( GameActions.fetchQuestionsFailed(response.data.error ) )

  const categories = response.data.data

  console.log('categories: ', categories)

  yield put( GameActions.fetchQuestionsSuccess( categories ) )

}
