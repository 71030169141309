import { createActions,  createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  startGameForUser: ['name', 'email', 'dni', 'phone', 'signature'],
  selectCharacter: ['character'],

  fetchQuestions: [],
  fetchQuestionsSuccess: ['categories'],
  fetchQuestionsFailed: ['error'],

  selectCategory: ['category'],
  getRandomQuestionOfSelectedCategory: [],
  incrementAnsweredQuestion: [],
  clearGame: [],
  incrementScore: ['scoreEarned'],

  addPlayer: ['user', 'score'],
  exportPlayers: [],
  cleanPlayers: [],

  addPrize: ['prize'],
})

const INITIAL_STATE = Immutable({
  user: null,
  character: null,
  categories: null,
  loadingQuestionsByCategories: false,
  finishedCategoriesRequest: false,
  score: 0,
  selectedCategory: null,
  currentQuestion: null,
  answeredQuestions: 0,

  players: [],
})

export const GameTypes = Types
export default Creators

export const startGameForUser = (state, { name, email, dni, phone, signature }) =>
  state.merge({ user: { name, email, dni, phone, signature } })

export const selectCharacter = (state, { character }) =>
  state.merge({ character })

export const fetchQuestions = state =>
  state.merge({ error: null, loadingQuestionsByCategories: true, finishedCategoriesRequest: false })

export const fetchQuestionsSuccess = (state, { categories }) =>
  state.merge({
    categories,
    loadingQuestionsByCategories: false,
    error: null,
    finishedCategoriesRequest: true })

export const fetchQuestionsFailed = (state, { error }) =>
  state.merge({
    error,
    loadingQuestionsByCategories: false,
    finishedCategoriesRequest: true  })

export const selectCategory = (state, { category }) =>
  state.merge({ selectedCategory: category })

export const getRandomQuestionOfSelectedCategory = (state) => {

  const currentCategory = state.selectedCategory

  if (!currentCategory) return state
  const availableQuestions = currentCategory.questions.filter( question => !question.answered )

  let selectedQuestion = null

  if (availableQuestions.length === 0) {
    const random = Math.floor( Math.random() * currentCategory.questions.length )
    selectedQuestion = currentCategory.questions[random]
  }else {
    const random = Math.floor( Math.random() * availableQuestions.length )
    selectedQuestion = availableQuestions[random]
  }

  return state.merge({ currentQuestion: selectedQuestion })
}

export const incrementAnsweredQuestion = state =>
  state.merge({ answeredQuestions: state.answeredQuestions + 1})

export const clearGame = state => state.merge({
  user: null,
  character: null,
  score: 0,
  selectedCategory: null,
  currentQuestion: null,
  answeredQuestions: 0,
})

export const incrementScore = (state, { scoreEarned }) =>
  state.merge({ score: state.score + scoreEarned })

export const addPlayer = (state, { user, score } ) =>
  state.merge({ players: state.players.concat([ { ...user, score } ]) })

export const exportPlayers = state => state

export const cleanPlayers = state => state.merge({ players: []})

export const addPrize = (state, { prize }) => state.setIn(['user', 'prize'], prize)

export const reducer = createReducer(INITIAL_STATE, {
  [Types.START_GAME_FOR_USER]: startGameForUser,
  [Types.SELECT_CHARACTER]: selectCharacter,

  [Types.FETCH_QUESTIONS]: fetchQuestions,
  [Types.FETCH_QUESTIONS_SUCCESS]: fetchQuestionsSuccess,
  [Types.FETCH_QUESTIONS_FAILED]: fetchQuestionsFailed,

  [Types.SELECT_CATEGORY]: selectCategory,
  [Types.GET_RANDOM_QUESTION_OF_SELECTED_CATEGORY]: getRandomQuestionOfSelectedCategory,
  [Types.INCREMENT_ANSWERED_QUESTION]: incrementAnsweredQuestion,

  [Types.CLEAR_GAME]: clearGame,
  [Types.INCREMENT_SCORE]: incrementScore,

  [Types.ADD_PLAYER]: addPlayer,

  [Types.EXPORT_PLAYERS]: exportPlayers,
  [Types.CLEAN_PLAYERS]: cleanPlayers,

  [Types.ADD_PRIZE]: addPrize,

})
