import React, { Component } from 'react'

import LaunchScreen from './Containers/LaunchScreen'
import UserDataScreen from './Containers/UserDataScreen'
import SelectCharacterScreen from './Containers/SelectCharacterScreen'
import DownloadDataScreen from './Containers/DownloadDataScreen'
import RouletteScreen from './Containers/RouletteScreen'
import QuestionScreen from './Containers/QuestionScreen'
import CongratulationsScreen from './Containers/CongratulationsScreen'

import {
  HashRouter as Router,
  Route,
  Switch} from 'react-router-dom'

class App extends Component {
  render() {
    return (
      <Router>
        <main role="application" className="viewport">
          <Switch>
            <Route
              path="/"
              exact
              component={props => <LaunchScreen {...props} />} />

            <Route
              path="/user-data"
              exact
              component={props => <UserDataScreen {...props} />} />
            <Route
              path="/character"
              exact
              component={props => <SelectCharacterScreen {...props} />} />
            <Route
              path="/sync-data"
              exact
              component={props => <DownloadDataScreen {...props} />} />
            <Route
              path="/roullete"
              exact
              component={props => <RouletteScreen {...props} />} />

            <Route
              path="/question"
              exact
              component={props => <QuestionScreen {...props} />} />

            <Route
              path="/congrats"
              exact
              component={props => <CongratulationsScreen {...props} />} />

          </Switch>
        </main>
      </Router>
    )
  }
}

export default App
