import { call, put, select } from 'redux-saga/effects'
import { exportPlayersOnAPI } from '../Services/Players'
import GameActions from '../Redux/Game'

export function * exportPlayers(action) {
  const players = yield select( state => state.game.players )
  console.log('Players: ', players)
  const response = yield call( exportPlayersOnAPI, players)
  console.log('Users response: ', response)
  if (response.ok) yield put( GameActions.cleanPlayers() )
}
