import React, { Component } from 'react'

import './Styles/ButtonStyles.css'

class Button extends Component {

  render() {
    return (
      <div className="ButtonContainer">
        <button onClick={ this.props.onPress }>
          { this.props.children }
        </button>
      </div>
    )
  }

}

export default Button
