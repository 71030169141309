import React, { Component } from 'react'

import backgroundImage from '../Assets/back-launch-logo.jpg'
import activityLogo from '../Assets/logo-gram.png'
import qrImage from '../Assets/qr-2.png'

import Logo from '../Components/Logo'

import { connect } from 'react-redux'
import GameActions from '../Redux/Game'

import classNames from 'classnames'

import './Styles/GeneralStyles.css'
import './Styles/CongratulationsScreenStyles.css'

class CongratulationsScreen extends Component {

  componentDidMount() {
    setTimeout( () => {
      this.props.clearGame()
      this.props.history.push({ pathname: '/' })
    }, 10000)
  }

  render() {
    return (
      <div
        style={ { backgroundImage: 'url('+backgroundImage+')', backgroundSize: 'cover', backgroundPosition: 'center' } }
        className={classNames(['screen', 'CongratulationsScreenContainer']) }>
        <div className="CongratulationsScreenLogo" style={ { paddingTop: 120, paddingBottom: 120 } }>
          <Logo />
        </div>

        <div style={{ zIndex: 2}}>
          { this.props.character &&
            <img style={{ width: 628, marginTop: 400 }} src={ this.props.character.image } />
          }
        </div>

        <div style={{ zIndex: 2}} className="PrizeInfoContainer">
          <div className="PrizeInfo">
            <span className="PrizeInfoName">
              Felicitaciones  { this.props.user ? this.props.user.name : " "}
            </span>
            <span className="PrizeInfoPrize">
              Tu puntaje final es de { this.props.score } puntos
            </span>
          </div>
        </div>

        <div style={{ zIndex: 9999}} className="box-container-fields">
            <div>
              <img src={qrImage} />
            </div>
            <div className='subtitle'>
              <p>2. Escanea este QR y déjanos tus datos</p>
            </div>
          </div>

        <div style={{ zIndex: 2}}>
          <img width={150}  height={150} src={ activityLogo } />
        </div>

        <div id="CongratulationsScreenBackground" />

      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    user: state.game.user,
    score: state.game.score,
    character: state.game.character,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearGame: () =>
      dispatch( GameActions.clearGame() ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (CongratulationsScreen)
